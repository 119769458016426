import "../styles/main.sass";

const ContactPage = () => {
  return (
    <section id="contact" className="section contact">
      <p className="headline">For any questions please mail me:</p>
      <p className="email">thiagofa2506@gmail.com</p>
    </section>
  );
};

export default ContactPage;
